#div {
  text-align: justify;
  background-color: black;
  color: white;
  white-space: pre-wrap;
  font-family: monospace;
  width: fit-content;
  height: fit-content;
}

html,
body,
#root,
video {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
}

video {
  height: auto;
  width: auto;
}

video {
  display: none;
}

#root {
  background-color: black;
}

#menu {
  color: white;
  position: fixed;
  z-index: 999;
  user-select: none;
  font-size: 22px;
  bottom: 0px;
  left: 0px;
  backdrop-filter: blur(6px);
  background-color: rgba(44, 44, 44, 0.6);
  border-top-right-radius: 8px;
}

.menu-container {
  padding: 5px;
}

.MuiDrawer-root > .MuiPaper-root {
  backdrop-filter: blur(6px);
  background-color: rgba(44, 44, 44, 0.6);
  color: white;
  font-size: 22px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 200px;
}

.container {
  display: flex;
  padding: 10px 10px;
  align-items: center;
  cursor: pointer;
  height: 26px;
  user-select: none;
}

.container .MuiSlider-root {
  color: inherit;
  padding: 0 0;
}

.container.active {
  color: rgb(55, 173, 255);
}

.container svg {
  margin-right: 10px;
}

.container svg.trail {
  margin-right: 0px;
  margin-left: 10px;
}

.container .timer {
  margin-right: 15px;
}
